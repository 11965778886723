export default function PrimaryButton({ className = '', disabled, type = "submit", children, ...props }) {
    return (
        <button
            {...props}
            type={type}
            className={
                `inline-flex items-center 2xl:px-16 lg:px-12 px-8 md:py-[9.25px] py-[5.25px] bg-yellow border-3 lg:border-transparent border-yellow rounded-20 font-bold text-25 text-black hover:bg-gray-700 focus:bg-dark-blue active:bg-dark-blue hover:bg-dark-blue focus:text-yellow hover:text-yellow active:text-yellow focus:outline-none transition ease-in-out duration-150 ${
                    disabled && 'opacity-25'
                } ` + className
            }
            disabled={disabled}
        >
            {children}
        </button>
    );
}
